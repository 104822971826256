import ready from 'ready'

ready(() => {
  if (window.location.hash == "#contact") {
    window.Beacon('navigate', '/ask/');
    window.Beacon('toggle');
  }
  document.addEventListener('click', function(e){
    var beaconToggle = e.target.getAttribute('data-beacon-toggle');
    if (beaconToggle) {
      e.preventDefault();
      if (beaconToggle == "ask") {
        window.Beacon('navigate', '/ask/');
      }
      window.Beacon('toggle');
    }
  }, true)
})
